import { inject } from '@angular/core';
import { Router, UrlTree } from '@angular/router';
import { Observable, combineLatest } from 'rxjs';
import { first, map } from 'rxjs/operators';

import { CampaignStatus } from '@ygm/common/core/models/campaign';
import { ChamberValidationService } from '@ygm/common/core/services/chamber-validation.service';

import { injectWebAppRoutes } from '../../features/shared/web-route-paths';
import { UserService } from '../services/user.service';

/** Guard prevents logged in user from accessing if the current campaign is in the renewal process. */
export function authorizedAndRenewedCampaignGuard(): Observable<boolean | UrlTree> {
	const routePaths = injectWebAppRoutes();
	const router = inject(Router);

	const chamberValidationService = inject(ChamberValidationService);
	const userService = inject(UserService);

	return combineLatest([
		chamberValidationService.chamberInformation$,
		userService.isAuthorized$,
	]).pipe(
		map(([chamberInfo, isAuthorized]) => {
			if (!isAuthorized) {
				return true;
			}
			return chamberInfo.campaign?.status === CampaignStatus.Renewal ? router.parseUrl(routePaths.renewalContracts.path) : true;
		}),
		first(),
	);
}
